import { Link, useHistory } from "react-router-dom";
import classNames from "classnames";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { MAIN_MENU_ITEMS } from "consts/menuPaths";
import { DEFAULT_AVATAR } from "consts/icons";
import { STATUS_ACTIVATED, STATUS_ACTIVATED_NEW } from "consts/general";
import * as PATH from "consts/routePaths";
import icon_logout from "edu_lms_v2/assets/img/icon-logout.svg";
import { useAuthStore } from "stores/authStore";
import { onDispatchDataInfo } from "edu_lms/modules/SignIn/actions";
import { cleanLocalStorage } from "edu_lms/constants/general";
import ButtonActivateBook from "components/ButtonActivateBook";
import MainMenuItem from "./MainMenuItem";
import ProfileMenu from "./ProfileMenu";
import { postLogout } from "../../../edu_lms/services/logout/logout";

export default function MainMenu({ isActiveNavbar, toggleNavBar }) {
  const openLoginModal = useAuthStore((state) => state.openLoginModal);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const setIsLoggedIn = useAuthStore((state) => state.setIsLoggedIn);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = history.location.pathname;
  const avatar = useSelector((state) => state.signInReducers.user_info.avatar);
  const fullName =
    useSelector((state) => state.signInReducers.user_info.full_name) ||
    "user2022";

  const filteredMenuItems = MAIN_MENU_ITEMS.filter((item) => {
    return [STATUS_ACTIVATED, STATUS_ACTIVATED_NEW].includes(item.status);
  });

  const menus = filteredMenuItems.map((item) => {
    let isActive = false;
    if(location.includes(item.path) || item.path === location) {
      isActive = true;
    }
    
    const hasSubMenuActive = Boolean(
      item.subMenu?.find((subMenuItem) => subMenuItem.path === location)
    );
    return { ...item, isActive: hasSubMenuActive || isActive };
  });

  const handleLogout = () => {
    setIsLoggedIn(false);
    postLogout();
    cleanLocalStorage();

    const userInfo = {
      job_id: "",
      full_name: "",
      birth_day: "",
      gender_id: "",
      email: "",
      phone: "",
      province_id: "",
      district_id: "",
      ward_id: "",
      school_id: "",
      grade_id: "",
      avatar: "",
    };

    dispatch(onDispatchDataInfo(userInfo));

    history.push(PATH.ROUTE_PATH_V2_HOME);
  };

  return (
    <>
      <button
        className={classNames("navbar-toggler border-0 ", {
          active: isActiveNavbar,
        })}
        type="button"
        onClick={() => {
          toggleNavBar();
        }}
      >
        <span />
      </button>
      <div className="navbar-collapse" id="navbar">
        <ListItem className="navbar-nav">
          {menus.map((item, index) => (
            <MainMenuItem item={item} key={index} />
          ))}
        </ListItem>
        <ProfileMenu />
        <div className="bottom m-show-flex">
          <ButtonActivateBook />
          {isLoggedIn ? (
            <Link
              to={""}
              title="Đăng xuất"
              className="btn-login flex-center"
              data-toggle="modal"
              data-target="#modal-login"
              onClick={handleLogout}
            >
              <img src={icon_logout} alt="icon_logout" className="mr-3" /> Đăng
              xuất
            </Link>
          ) : (
            <div
              title="Đăng nhập"
              className="btn-login flex-center cursor"
              data-toggle="modal"
              data-target="#modal-login"
              onClick={openLoginModal}
            >
              <img src={icon_logout} alt="icon_logout" className="mr-3" /> Đăng
              nhập
            </div>
          )}
        </div>
      </div>
      {isLoggedIn ? (
        <div className="avatar flex-lc" id="avatar-desktop-screen">
          <div className="avatar__img">
            <img
              src={avatar || DEFAULT_AVATAR}
              className="fit"
              alt="Ảnh đại diện"
            />
          </div>
          <FullNameMobile className="avatar__name avatar-toggle postion-absolute text-truncate">
            {fullName}
          </FullNameMobile>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <Link
            to={PATH.ROUTE_PATH_TU_SACH}
            className="activated flex-center btn-activate-book mr-2 d-md-block d-lg-none"
          >
            Tủ sách
          </Link>
          <Link
            to={PATH.ROUTE_PATH_V3_REGISTER}
            className="btn-register flex-center m-show-flex"
          >
            Đăng Ký
          </Link>
        </div>
      )}
    </>
  );
}

const ListItem = styled.ul`
  .active {
    color: #ff7707 !important;
  }
  @media screen and (min-width: 992px) {
    .icon-new {
      width: 20px;
      top: 12px;
      right: -15px;
    }
  }
  @media screen and (max-width: 991px) {
    .icon-new {
      width: 20px;
      top: 12px;
      left: 75px;
    }
  }

  z-index: 9;
`;

const FullNameMobile = styled.div`
  right: 50px;
  max-width: 150px;
`;
